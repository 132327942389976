









































import { BaseUser } from "@sportango/backend";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({
  name: "user-chip"
})
export default class UserChip extends Vue {
  @Prop({ required: false })
  private user!: BaseUser;
  @Prop({ required: false, default: "primary", type: String })
  private color!: string;
  @Prop({ required: false, default: false })
  small!: boolean;
  @Prop({ required: false })
  maxWidth!: number;
}
