import Vue from "vue";
import { VChip } from "vuetify/lib";

export const SportangoChip = Vue.component("SportangoChip", {
  extends: VChip,
  props: {
    small: {
      default: true,
      type: Boolean
    },
    outlined: {
      default: true,
      type: Boolean
    }
  }
});
