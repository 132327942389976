





















































































import { CALLABLE_FUNCTIONS } from "@/utils/callables";
import {
  EMAIL_VALIDATION_RULES,
  PASSWORD_VALIDATION_RULES,
  requiredFieldRule,
  ValidationRules
} from "@/utils/validation";
import { WatchLoading } from "@/decorators/Loading";
import { DelayMixin, LoadingMixin } from "@/mixins/Helpers";
import { httpsCallable } from "@firebase/functions";
import Component, { mixins } from "vue-class-component";
import {
  BaseUser,
  RegisterUserRequest,
  RegisterUserResponse,
  UserInvite,
  USER_INVITES
} from "@sportango/backend";
import { Auth, DB, Functions, resolveUserFromAuthState } from "@/firebase";
import { save } from "@/store/localStorage";
import {
  sendEmailVerification,
  signInWithEmailAndPassword
} from "@firebase/auth";
import { SportangoTextField } from "@/components/Inputs/overrides";
import { Watch } from "vue-property-decorator";
import { collection, doc, getDoc } from "@firebase/firestore";
import UserChip from "@/components/common/UserChip.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { SportangoChip } from "@/components/common/overrides";

@Component({
  name: "register-player",
  components: {
    SportangoChip,
    UserChip,
    LottieAnimation,
    SportangoTextField
  }
})
export default class RegisterPlayer extends mixins(LoadingMixin, DelayMixin) {
  isValid = false;
  email = "";
  password = "";
  confirmPassword = "";
  errorMessage: string | null = null;
  userInvite: UserInvite | null = null;
  isCoachLoaded = false;
  readonly requiredFieldRule = requiredFieldRule;
  readonly EMAIL_VALIDATION_RULES = EMAIL_VALIDATION_RULES;
  readonly PASSWORD_VALIDATION_RULES = PASSWORD_VALIDATION_RULES;
  confirmPasswordRules: ValidationRules = [
    ...PASSWORD_VALIDATION_RULES,
    this.isSamePassword
  ];

  isSamePassword(v: string): true | string {
    return v === this.password || "Passwords do not match";
  }

  get coachId(): string | null {
    if (typeof this.$route.query.coachId === "string") {
      return this.$route.query.coachId;
    } else if (this.userInvite?.coachId) {
      return this.userInvite.coachId;
    }
    return null;
  }

  get coachInfo(): BaseUser | undefined {
    return this.$store.getters.users.find((u) => u.uid === this.coachId);
  }

  get isEmailProvided(): boolean {
    if (this.userInvite?.email) {
      return true;
    }
    return false;
  }

  async mounted() {
    await this.watchCoachId();
    if (typeof this.$route.query.invite === "string") {
      const inviteInfo = await getDoc(
        doc(collection(DB, USER_INVITES), this.$route.query.invite)
      );
      if (inviteInfo.exists()) {
        this.userInvite = inviteInfo.data() as UserInvite;
        this.email = this.userInvite.email;
      }
    }
  }

  @Watch("coachId")
  async watchCoachId() {
    this.isCoachLoaded = false;
    if (this.coachId) {
      await this.$store.dispatch("getUsersById", this.coachId);
    }
    this.isCoachLoaded = true;
  }

  @WatchLoading()
  async register(): Promise<void> {
    if (this.coachId) {
      const registerWithEmailAndPassword = httpsCallable<
        RegisterUserRequest,
        RegisterUserResponse
      >(Functions, CALLABLE_FUNCTIONS.registerWithEmailAndPassword);
      const payload: RegisterUserRequest = {
        email: this.email,
        password: this.password,
        roles: ["player"],
        coachId: this.coachId
      };
      await registerWithEmailAndPassword(payload);
    }
    save("userEmail", this.email, "string");
    await signInWithEmailAndPassword(Auth, this.email, this.password);
    const user = await resolveUserFromAuthState();
    if (user) {
      await this.delay(15000);
      await sendEmailVerification(user);
    }
  }
}
